/* eslint-disable no-unused-expressions */
/* eslint-disable no-undef */
// this method is deleted once it's run
export default () => {
  Cloud.setup &&
    Cloud.setup({
      apiBaseUrl: import.meta.env.VITE_API_ENDPOINT,
      methods: REMOTE_ENDPOINTS_GLOBAL,
    });

  return Cloud;
};
